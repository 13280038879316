import React, { useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../../../components/Layout/Layout"
import Article from "../../../components/Article/Article"
import CallToAction from "../../../components/Article/CallToAction"
import Breadcrumbs from "../../../components/Breadcrumbs/Breadcrumbs"
import i18n from "i18next"

const SeoArticle = ({ data }) => {
  useEffect(() => {
    i18n.changeLanguage("pl")
  }, [])
  return (
    <Layout
      seo={{
        title: "Czy aktywność w mediach społecznościowych ma wpływ na SEO?",
        description:
          "Obecność marki czy firmy w Social Media przynosi wiele korzyści. Czy jednak aktywność na portalach społecznościowych wpływa na pozycję strony w Google? Sprawdź!",
        schema: {
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          itemListElement: [
            {
              "@type": "ListItem",
              position: 1,
              item: {
                "@id": "/",
                name: "Główna",
              },
            },
            {
              "@type": "ListItem",
              position: 2,
              item: {
                "@id": "/baza-wiedzy/artykuly/",
                name: "Baza wiedzy",
              },
            },
            {
              "@type": "ListItem",
              position: 3,
              item: {
                "@id": "/baza-wiedzy/artykul/aktywnosc-w-mediach-spolecznosciowych-a-seo/",
                name: "Czy aktywność w mediach społecznościowych ma wpływ na SEO?",
              },
            },
          ],
        },
      }}
    >
      <Breadcrumbs
        path={[
          { url: "/", text: "Główna" },
          {
            url: "/baza-wiedzy/artykuly/",
            text: "Baza wiedzy / Artykuły",
          },
          {
            url: "/baza-wiedzy/artykul/aktywnosc-w-mediach-spolecznosciowych-a-seo",
            text: "Czy aktywność w mediach społecznościowych ma wpływ na SEO?",
          },
        ]}
      />
      <Article
        img={
          <img
            src={data.file.childImageSharp.fluid.src}
            alt="Czy aktywność w mediach społecznościowych ma wpływ na SEO?"
          />
        }
        slug="/baza-wiedzy/artykul/aktywnosc-w-mediach-spolecznosciowych-a-seo"
      >
        <h1>Aktywność w mediach społecznościowych a SEO</h1>
        <hr />
        <p>
          <strong>
            Obecność firmy czy marki w mediach społecznościowych umożliwia wchodzenie w bezpośrednie interakcje z
            klientami i budowanie z nimi trwałych więzi. Przemyślana komunikacja w Social Media to nie tylko świetna
            forma promocji i miejsce kreowania wizerunku. W pośredni sposób może zwiększać również wartość strony
            internetowej i wpływać na SEO. Czy aktywność na Google Moja Firma, Twitterze lub Facebooku ma wpływ na
            pozycję strony w wyszukiwarce? Dowiesz się z artykułu.{" "}
          </strong>
        </p>
        <h2>Wykorzystanie Google Moja Firma w pozycjonowaniu</h2>
        <p>
          Google Moja Firma to usługa, która pomaga zwiększyć potencjał strony w wynikach wyszukiwania. Największe
          korzyści przynosi małym i średnim przedsiębiorstwom. Firmy, które mają wizytówkę w Google My Business,
          zwiększają swoją widoczność na zapytania lokalne i szybciej pozyskują nowych klientów. Wizytówki wyświetlają
          się bowiem nad organicznymi wynikami. Ważne jest, by Twoja wizytówka była uzupełniona w 100% i zawierała
          wszystkie niezbędne, aktualne i wyczerpujące dane o firmie, takie jak m.in. nazwa firmy, dokładny jej adres,
          dane kontaktowe czy godziny pracy. Na profilu powinien też koniecznie znaleźć się{" "}
          <strong>link do Twojej witryny internetowej</strong>. Dzięki temu użytkownicy będą mogli szybko przejść na
          stronę czy do sklepu online.{" "}
        </p>
        <p>
          Należy jednak pamiętać, że samo założenie profilu nie wystarczy. Trzeba na bieżąco go aktualizować i
          wykorzystywać do komunikacji z klientami. Jest to ważne nie tylko z punktu widzenia użytkownika, ale też
          wyszukiwarki. Profil na GMB to doskonałe miejsce do prezentowania swojej oferty, promocji czy treści
          reklamowych. Możesz na nim też dzielić się nowościami, informacjami dotyczącymi działalności firmy oraz
          ważnymi ogłoszeniami. Tworząc wpisy na Google Moja Firma mamy możliwość dodawania zdjęć, filmów, publikowania
          wydarzeń czy ofert. Każdy wpis powinien zawierać czytelny, interesujący i chwytliwy tytuł oraz bardziej
          szczegółowy opis. Pamiętaj, by stosować język korzyści i przedstawiać największe zalety Twojej oferty.{" "}
        </p>
        <p>
          GMB ma też opcję wystawiania ocen i dodawania opinii przez osoby, które skorzystały już z usług danej firmy.
          Jest to bardzo istotne dla biznesu, gdyż wielu klientów w trakcie podejmowania decyzji o zakupie produktów czy
          usług kieruje się właśnie opiniami innych ludzi. Twoim zadaniem jest reagowanie na komentarze i nawiązywanie
          interakcji z użytkownikami. Jeśli zdarzy się, że otrzymasz jakąś negatywną opinię, powinieneś koniecznie na
          nią odpowiedzieć.
        </p>
        <p>
          Regularne publikowanie wpisów na profilu w Google Moja Firma sprawi, że będzie on{" "}
          <strong>lepiej widziany przez wyszukiwarkę, dzięki czemu znajdzie się wyżej w wynikach wyszukiwania</strong>.
          Przyczyni się to do zwiększenia ilości odwiedzin i popularności. Aktywny profil ma też większą szansę na
          znalezienie się na mapach Google. Wypozycjonowana wizytówka da Ci więc możliwość wypromowania Twojej strony
          internetowej i biznesu. Pamiętaj jednak, że sama wizytówka nigdy nie zastąpi profesjonalnych działań SEO.
          Roboty Google podczas oceny biorą bowiem pod uwagę także dodaną do wizytówki stronę WWW.{" "}
        </p>
        <p className="text-center">
          <CallToAction
            to={"/"}
            text={"Wykonaj darmowy audyt strony i popraw jej widoczność w wyszukiwarce. Wykonaj teraz >>"}
          />
        </p>
        <h2>Twitter a SEO</h2>
        <p>
          Twitter jest rozpowszechniony głównie wśród polityków, dziennikarzy czy blogerów. Tak naprawdę, jest to jednak
          także doskonałe miejsce dla wielu firm. Za pośrednictwem Twittera można komunikować się ze swoimi odbiorcami i
          zwiększyć świadomość marki wśród swoich potencjalnych klientów.{" "}
        </p>
        <p>
          Tweet to wpis o długości maksymalnie 280 znaków, do którego można dodawać też zdjęcia, materiały wideo czy
          gify. Trafia on do osób, które obserwują dany profil, ale także do odbiorców, którzy trafili na post poprzez
          konkretny hashtag. Stosowanie odpowiednich hashtagów jest bardzo ważne, gdyż określa tematykę wpisu i pozwala
          dotrzeć do większej liczby użytkowników. Twitter to dobre miejsce do linkowania zewnętrznego, dlatego warto w
          każdym wpisie umieszczać link do swojej strony internetowej. Polecaną praktyką jest także dodawanie do
          udostępnianych w postach grafik opisów alternatywnych, które pozwolą robotom Google rozpoznać ich tematykę.
        </p>
        <p className="inserted-seo-link">
          Sprawdź,{" "}
          <a
            href="https://blog.wenet.pl/seo-pozycjonowanie/czym-jest-audyt-seo-dlaczego-jest-potrzebny-wlascicielom-stron-www/"
            target="_blank"
            rel="noreferrer"
          >
            czym jest audyt SEO
          </a>{" "}
          strony. Dowiedz się już teraz!
        </p>
        <p>
          Warto być aktywnym na tym portalu społecznościowym również dlatego, że kilka lat temu Google i Twitter
          nawiązały współpracę, wskutek czego coraz częściej w SERP można zobaczyć tzw. karuzele z wpisami umieszczonymi
          właśnie na Twitterze. Wyniki te odnoszą się do wyszukiwanych przez internautów słów kluczowych bądź hashtagów.
          Dzięki temu prowadząc profil i{" "}
          <strong>
            dodając regularnie wpisy, możesz pozyskać nowych użytkowników oraz zwiększyć ruch na swojej stronie WWW, co
            może pomóc podnieść jej widoczność w wyszukiwarce
          </strong>
          .
        </p>
        <p>
          Ważne jest też, by Twój profil na Twitterze był odpowiednio zoptymalizowany. Tylko wówczas roboty indeksujące
          będą w ogóle brać pod uwagę Twoje posty. Powinieneś wybrać nazwę, która będzie spójna z Twoją stroną
          internetową oraz zadbać o przyjazny adres URL. Profil musi zawierać też aktywny link do strony internetowej, a
          dodatkowo krótki opis działalności firmy, lokalizację oraz logo firmy.
        </p>
        <h2>Facebook i jego wpływ na SEO</h2>
        <p>
          Nie ulega wątpliwości, że Facebook jest wciąż jedną z największych platform społecznościowych na świecie.
          Każdego dnia użytkownicy publikują na nim setki tysięcy postów oraz zostawiają jeszcze więcej polubień i
          udostępnień. Ruch na tym portalu jest ogromny, nic więc dziwnego, że obecna jest na nim większość marek.
        </p>
        <p>
          Boty Google indeksują zawartość Facebooka tak samo, jak inne strony. Są jednak jak niezalogowani użytkownicy,
          dlatego nie można ograniczać im dostępu do swoich informacji i treści na profilu. Profil i wszystkie posty
          muszą być więc publiczne, by mogły pojawić się w wyszukiwarce.
        </p>
        <p>
          Fanpage Twojej firmy, tak samo, jak w przypadku wspomnianych już profili na Google Moja Firma czy Twitterze,
          powinien być dokładnie uzupełniony. W zakładce Informacje muszą znaleźć się oczywiście aktualne informacje o
          firmie, dane kontaktowe oraz link do witryny internetowej. Jeśli Twój profil jest już w pełni zoptymalizowany,
          możesz publikować swoje przyciągające uwagę posty. Pamiętaj, by umieszczać w nich skrócone linki do swojej
          oferty na stronie czy udostępniać artykuły z bloga firmowego.{" "}
          <strong>
            Wartościowe treści są chętniej udostępniane przez użytkowników i mają szansę dotrzeć do większej liczby
            odbiorców, dzięki czemu Twoja strona będzie częściej odwiedzana
          </strong>
          . Google uzna ją za interesującą zwłaszcza w momencie, gdy linki do niej będą udostępniane przez duże i
          popularne profile.
        </p>
        <p className="inserted-seo-link">
          Popraw widoczność serwisu w wyszukiwarce i wykonaj <a href="/">audyt strony internetowej</a>.
        </p>
        <p>
          Mimo że wszystkie linki umieszczone zarówno na Facebooku, jak i na innych mediach społecznościowych oznaczane
          są atrybutem nofollow, to i tak mają znaczenie dla strony internetowej. Przede wszystkim tworzą ruch,
          sprawiają, że więcej osób ma szansę poznać Twoje produkty czy usługi i wpływają na wzrost rozpoznawalności
          firmy. Poza tym internauci chętnie wyszukują w Social Media nowe marki, a gdy profil ich zaciekawi, często
          postanawiają skorzystać z wyszukiwarki i odnaleźć w niej daną firmę. Takie zachowania społeczności z pewnością
          są zauważane i doceniane przez algorytmy Google.
        </p>
        <p>
          Podsumowując, media społecznościowe nie mają bezpośredniego wpływu na SEO, jednak są świetnym{" "}
          <strong>dodatkiem do każdej strony WWW i pomagają wzmocnić jej pozycję w wynikach wyszukiwania</strong>. Warto
          więc aktywnie prowadzić swój profil firmowy w wybranym serwisie i dzielić się ze swoimi odbiorcami
          wartościowym contentem. Na firmowej witrynie należy zaś umieścić odnośniki do swoich istniejących już profili,
          by internauci mogli szybko się do nich przenieść. Integracja strony internetowej z profilami w Social Media
          będzie przydatna, zwłaszcza gdy prowadzisz firmowego bloga. Wówczas w każdym wpisie będzie umieszczony
          przycisk do udostępniania, który zachęci czytelników do dzielenia się Twoimi treściami.{" "}
        </p>
      </Article>
    </Layout>
  )
}

export const query = graphql`
  {
    file(relativePath: { eq: "img/full/media-spolecznosciowe.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1100, jpegQuality: 90) {
          sizes
          src
        }
      }
    }
  }
`

export default SeoArticle
